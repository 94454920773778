export default {
  primary: {
    backgroundColor: 'primary',
    color: 'light',
    border: 'solid 2px',
    borderColor: 'primary',
    ':hover': {
      backgroundColor: 'transparent'
    }
  },
  secondary: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#6d717d',
    height: '150px',
    width: '150px',
    color: '#f3d564',
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: '400',
    borderRadius: '500px',
    border: 'none',
    '&:hover': {
      color: 'white',
      backgroundColor: 'secondary'
    }
  }
}
