export default {
  heroV2: {
    container: {},
    hero: {
      height: '100vh',
      width: '100%',
      display: 'block',
      position: 'relative',

      '.slick-initialized .slick-slide > div': {
        overflow: 'hidden',
        position: 'relative',
        height: '100vh'
      },
      '.slick-slide img': {
        height: '100vh',
        width: '100%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        objectFit: 'cover',
        transform: 'translate(-50%, -50%)',
        filter: 'brightness(0.95)'
      },
      '.slick-dots': {
        bottom: '25px'
      },
      '.slick-prev:before, .slick-next:before': {
        color: 'white',
        fontSize: '25px'
      },

      '.slick-dots li button:before': {
        color: 'white',
        fontSize: '10px'
      },
      '.slick-next': {
        right: '5px',
        zIndex: '500'
      },
      '.slick-prev': {
        left: '0px',
        zIndex: '500'
      },

      'svg.slick-arrow': {
        width: '30px',
        height: '30px',
        color: 'black',
        border: 'solid 1px lightgrey',
        display: ['none!important', '', '', '']
      }
    },

    content: {
      position: 'absolute',
      top: '50%',
      left: '0%',
      transform: 'translate(0%, -50%)',
      display: 'flex',
      flexDirection: 'column',
      padding: ['2rem', '2rem 3rem'],
      zIndex: '100',
      boxSizing: 'border-box',
      maxWidth: '767px',
      margin: ['', '', '', '3rem 0rem 0rem'],
      textShadow: ['2px 2px 4px black', '', ''],
      a: {
        display: 'flex',
        width: 'fit-content'
      },
      '.text': {
        color: '#f3d564',
        p: {
          fontSize: ['1.3rem', '1.5rem', '1.8rem'],
          fontWeight: '300',
          marginBottom: 3,
          whiteSpace: 'pre-wrap',
          // color: '#bb7b7d',
          color: '#f3d564'
        }
      }
    },
    logoHero: {
      maxHeight: '250px',
      margin: '0 auto 1rem'
    },
    title: {
      fontSize: ['1.8rem', '2rem', '2.5rem', '3rem'],
      marginBottom: 3,
      whiteSpace: 'pre-wrap',
      color: ['white', '', 'white']
    },
    subtitle: {
      fontSize: ['1.8rem', '2rem', '2.5rem', '3rem'],
      marginBottom: 3,
      whiteSpace: 'pre-wrap',
      color: ['white', '', 'white']
    },

    ctaButton: {
      margin: '1rem 0',
      backgroundColor: 'primary',
      color: 'white',
      width: 'fit-content',
      padding: '0.75rem 2.5rem',
      borderRadius: '500px'
    },

    // the widget has the same variants as the hero so we can't not pass down a variant name
    shoutWidgetContainer: {
      display: 'none',
      '.shoutWidget': {
        width: '100%',
        alignItems: 'stretch',
        display: 'flex',
        flexDirection: 'row',
        padding: '0rem',
        '.content': {
          flexDirection: 'column',
          justifyContent: 'center',
          flexGrow: '1',
          paddingLeft: 2,
          paddingRight: 1,
          paddingY: 1,
          width: ['65%', '', '50%'],
          color: 'text'
        },
        '.title': {
          fontSize: 3,
          fontWeight: 'bold',
          color: 'secondary'
        },
        '.text': {
          fontSize: '0.8rem',
          marginY: 1
        },
        '.date': {
          fontSize: 2,
          color: 'secondary',
          fontWeight: 'bold'
        },
        '.imageContainer': {
          width: ['35%', '', '50%'],
          img: {
            width: '100%',
            height: '100%',
            objectFit: 'cover'
          }
        },
        '.shoutCTAS': {
          flexWrap: 'wrap',
          marginBottom: '0.5rem',
          a: {
            margin: '0.5rem 1rem 0.5rem 0rem',
            textDecoration: 'underline',
            fontSize: ['1.25rem', '1.5rem'],
            ':hover': {
              color: 'primary'
            }
          }
        }
      }
    }
  }
}
