const variantName = 'navigationV3'

export default {
  [variantName]: {
    // ============================
    // ======== Container =========
    // ============================
    container: {
      position: 'fixed',
      width: '100%',
      top: '0px',
      left: '0px',
      zIndex: '1000',
      boxSizing: 'border-box',
      height: ['90px', '90px', '90px', '120px'],
      padding: ['0.5rem', '', '1rem 0.25rem'],
      display: 'flex',
      transition: 'all ease-in-out 0.5s',
      alignItems: 'center',
      justifyContent: 'flex-end',
      backgroundColor: 'transparent',
      color: 'white',

      // ! custom className styles
      '.book-now': {
        border: 'solid 1px',
        borderColor: 'primary',
        padding: ['0.75rem', '', '', '0.5rem', '', '0.75rem'],
        color: 'primary',
        ':hover': {
          backgroundColor: 'primary',
          color: 'light',
          a: {
            backgroundColor: 'primary',
            color: 'light'
          }
        }
      }
    },

    containerScrolled: {
      variant: `${variantName}.container`,
      backgroundColor: 'white',
      color: 'black',
      height: ['70px', '90px', '90px', '90px'],
      boxShadow: '2px 2px 10px grey',
      '.navItem': {
        a: {
          textShadow: 'unset',
          color: 'black'
        }
      }
    },

    // ============================
    // =======  Nav Logos =========
    // ============================

    logo: {
      position: ['absolute', '', '', 'static'],
      top: ['0px', '', '', '0rem'],
      left: ['0px', '', '', '0rem'],
      alignSelf: 'flex-start',

      // height: ['100%'],
      zIndex: '200',
      display: ['', '', '', 'inline'],
      img: {
        height: ['100%'],
        maxHeight: ['100px', '100px', '', '130px', '130px', '130px'],
        padding: 0
      }
    },
    logoScrolled: {
      variant: `${variantName}.logo`,
      maxHeight: ['100%', '100%', '100%', '100%', '100%', '100%']
    },

    // ============================
    // =======  Hamburger =========
    // ============================

    hamburger: {
      position: 'relative',
      display: ['flex', 'flex', 'flex', 'none'],
      zIndex: '2000',
      alignSelf: 'center',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '35px',
      height: '25px',
      cursor: 'pointer',
      margin: '0 0.5rem 0 auto',
      div: {
        alignSelf: 'flex-end',
        transition: 'all ease-in-out 0.3s',
        height: '4px',
        width: '100%',
        backgroundColor: 'secondary',
        borderRadius: '10px'
      }
    },
    hamburgerOpen: {
      position: 'relative',
      display: ['flex', 'flex', 'flex', 'none'],
      zIndex: '2000',
      alignSelf: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '35px',
      height: '25px',
      cursor: 'pointer',
      margin: '0 0.5rem 0 auto',
      div: {
        position: 'absolute',
        transition: 'all ease-in-out 0.3s',
        height: '4px',
        width: '100%',
        backgroundColor: 'text',
        boxShadow: 'none',
        borderRadius: '10px'
      },
      '& > :nth-child(1)': {
        transform: 'rotate(135deg) '
      },
      '& > :nth-child(2)': {
        transform: 'rotate(-135deg)'
      }
    },

    // ============================
    // ========  Nav Menu =========
    // ============================

    navMenu: {
      position: ['absolute', 'absolute', 'absolute', 'unset'],
      margin: '0px',
      boxSizing: 'border-box',
      justifyContent: ['flex-start', '', '', 'flex-end'],
      height: ['100vh', '100vh', '100vh', 'unset'],
      background: [
        "url('https://res.cloudinary.com/gonation/image/upload/v1609353990/sites/my-skin-spot/background.jpg')",
        '',
        '',
        'none'
      ],
      backgroundColor: ['primary', 'primary', 'primary', 'unset'],
      top: '0px',
      zIndex: '1000',
      width: ['100%', '100%', '100%', '100%'],
      minWidth: '250px',
      left: -'120%',
      transition: 'all ease-in-out 0.5s',
      padding: ['3rem 2rem 2rem', '', '', '0.5rem'],
      alignItems: ['flex-start', '', '', 'center'],
      justifyContent: ['center', '', '', 'flex-end'],
      display: 'flex',
      flexDirection: ['column', 'column', 'column', 'row'],
      overflow: ['hidden', '', '', 'initial'],
      listStyleType: 'none'
    },
    navItem: {
      marginBottom: ['0.75rem', '', '', '0px'],
      zIndex: '100',
      marginLeft: ['0px', '', '0px'],
      margin: ['', '', '', '0.3rem', '0.3rem', '0.5rem'],
      listStyleType: 'none',

      a: {
        textShadow: ['', '', '', '1px 1px 10px black'],
        color: ['black', '', '', 'white'],
        fontSize: ['1rem', '1.75rem', '2rem', '0.75rem', '0.75rem', '0.9rem', '1rem'],
        textTransform: 'uppercase',
        fontWeight: '400',
        letterSpacing: ['2px', '', '', '0px', '1px', '2px'],
        textDecoration: 'none',
        fontFamily: 'Montserrat, sans-serif !important',
        ':hover': {
          color: 'primary'
        }
      }
    },
    // ==========================
    // ====  Dropdown Styles ====
    // ==========================

    navItemDropdown: {
      alignItems: 'inherit',
      fontFamily: 'inherit',
      justifyContent: 'inherit',
      position: 'relative'
    },

    subNavItemDropdown: {
      variant: `${variantName}.navItem`,
      padding: '0.5rem',
      margin: ['0rem', '0rem', '0rem', '0rem'],
      whiteSpace: 'nowrap'
    },

    navItemDropdownMenu: {
      padding: '0.5rem',
      textShadow: '0px 0px 30px rgb(122 122 122 / 79%)',
      position: ['', '', '', 'absolute'],
      display: 'flex',
      alignItems: 'inherit',
      justifyContent: 'inherit',
      flexDirection: 'column',
      // width: '100%',
      backgroundColor: ['', '', '', 'primary'],
      color: ['', '', '', 'white'],
      // ! custom className styles
      '.navItem a': {
        ':hover': {
          color: 'light'
        }
      }
    },

    // ============================
    // ====  Extra Nav Widgets ====
    // ============================

    locationSwitcher: {
      backgroundColor: '',
      position: ['fixed', '', '', 'unset'],
      left: ['0'],
      top: ['35px'],
      transform: ['translateY(-50%)', '', '', 'unset'],
      zIndex: '2000'
    },
    socialIconsContainer: {
      margin: ['1.5rem 0rem', '', '', '0rem 0.5rem', '0rem 0.75rem'],
      svg: {
        marginRight: ['0.5rem', '', '', '0.25rem', '0.5rem'],
        width: ['25px', '', '', '20px', '25px'],
        height: ['25px', '', '', '20px', '25px'],
        path: {
          fill: 'secondary'
        }
      }
    },
    phoneContainer: {
      borderLeft: ['', '', '', '2px solid #c89495'],
      paddingLeft: ['', '', '', '0.75rem'],
      borderTop: ['4px solid', '', '', 'none'],
      borderColor: 'secondary',
      paddingTop: ['2rem', '', '', '0rem'],
      margin: ['1rem 0rem 0rem', '', '', '0rem 0.25rem', '0rem 0.75rem'],
      display: ['', '', '', 'none'],
      a: {
        fontSize: ['0.8rem', '', '', '1rem'],
        backgroundColor: 'primary',
        padding: ['0.75rem', '', '', '0.5rem', '0.75rem'],
        color: 'white',
        fontWeight: 'bold',
        letterSpacing: ['1px', '', '', '0px', '1px'],
        fontFamily: 'Montserrat, sans-serif !important',
        svg: {
          marginRight: '0.25rem'
        }
      }
    }
  }
}
