export default {
  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.container': {},
    '.containerScrolled': {},
    '.smallNavMenu > div': {
      '.navItem': {}
    },
    '.navItem': {},
    '.hamburger > div': {},

    '.navMenuLogo': {},
    '.navMenuOpen': {
      '.seperatorLine': {},
      '.navItem': {}
    },
    '.navBlockOpen': {},
    '.phoneSocialContainer': {},
    '.socialIconsContainer, .phoneContainer': {
      '.smallNavHeading': {}
    },
    '.containerScrolled': {
      '.hamburger > div': {}
    },
    '.logo': {
      img: {}
    },
    '.logoScrolled': {
      img: {}
    }
  },

  footer: {
    '.socialContainer, .contactDetails-container': {},
    '.sectionHeading': {},
    '.poweredBy .gonationLogo': {},
    '.copyright': {},
    '.multiButtonContainer': {}
  },

  ctaWidget: {},

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    color: 'primary',
    fontSize: ['1.5rem', '1.75rem', '2rem', '2.5rem'],
    fontWeight: '400'
  },

  subtitle: {
    textTransform: 'Capitalize',
    fontSize: ['1rem', '1.25rem', '1.5rem', '1.75rem'],
    color: '#adadad',
    fontWeight: '300',
    fontFamily: 'body'
  },

  text: {},

  sideBySide1: {
    '.lazyload-wrapper': {},
    '.content': {},
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.secondary'
    }
  },

  sideBySide2: {
    variant: 'customVariants.sideBySide1',
    '.lazyload-wrapper': {
      order: '2'
    }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  // homepageHero: {
  //   '.title': {
  //     variant: 'customVariants.title',
  //   },
  //   '.subtitle': {},
  //   color: 'light',
  //   '.shoutWidget': {
  //     display: 'none',
  //   },

  //   '.ctaButton': {
  //     variant: 'buttons.secondary',
  //   },
  // },
  homepageHeroShout: {
    display: 'none'
  },

  homepageShout: {
    padding: ['2rem 1rem', '', '', '3rem 1.5rem'],
    '.imageContainer': {
      '::before': {
        background: 'unset'
      }
    },
    '.content': {
      padding: ['3rem 1.5rem', '', '', '3.5rem'],
      border: 'solid 1px #cbb190',
      maxWidth: ['calc(100% - 1rem)'],
      width: '100%',
      margin: '0rem'
    },
    '.title': {
      textTransform: 'uppercase',
      textAlign: 'left',
      fontSize: ['1.75rem', '', '2rem'],
      marginBottom: '1rem',
      paddingBottom: '0.5rem'
    },
    '.text': {
      fontSize: ['1.75rem', '', '2rem'],
      textAlign: 'left',
      maxWidth: 'unset',
      marginBottom: '1.5rem'
    },
    '.date': {
      fontSize: '1rem',
      textAlign: 'left',
      maxWidth: 'unset'
    }
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1'
  },

  homepageBoxes: {
    padding: '0rem 0rem 0rem',
    marginTop: '3rem',
    // backgroundColor: '#f5f5f5',
    color: 'black',
    position: 'relative',
    // '.textContent': {
    //   display: 'none',
    // },
    '.imageFill': {
      paddingBottom: '140%'
    },
    '.mainTextContainer': {
      backgroundColor: 'primary',
      '.textContent': {
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        // display: 'none',
        '.title': {
          textTransform: 'unset',
          fontSize: ['1.25rem', '1.5rem', '1.8rem', '2rem'],
          color: '#fdfdfd',
          marginBottom: '0rem',
          textTransform: 'uppercase',
          fontWeight: '400'
        }
      }
    },
    '.slick-slider': {
      padding: '0.5rem 0px',
      position: 'static'
    },
    '.slick-arrow': {
      bottom: 'unset',
      right: '2rem',
      top: '2rem',
      color: 'white',
      display: ['none !important', '', 'block !important', '']
    },
    '.slick-prev': {
      right: '4rem',
      left: 'unset'
    },
    '.slick-dots': {
      bottom: '1rem',
      display: 'none !important'
    },
    '.slick-dots li.slick-active button:before': {
      color: 'primary'
    },
    '.slick-dots li button:before': {
      color: 'dark'
    },

    '.slideBox': {
      margin: '0rem',
      width: 'calc(100% - 0.5rem)!important',
      '.textContent': {
        alignItems: 'flex-start',
        display: 'none'
      },
      '.text': {
        textAlign: 'left',
        maxHeight: '146px',
        overflowY: 'scroll',
        scrollbarWidth: 'none',
        '::-webkit-scrollbar': {
          display: 'none'
        }
      }
    }
  },

  homepageHours: {
    variant: 'customVariants.sideBySide1'
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    paddingX: '0rem',
    paddingY: '1rem',
    marginBottom: '2rem',
    flexDirection: 'row',
    '.box': {
      padding: '1rem',
      width: ['calc(100% - 0.5rem * 2)', 'calc(50% - 0.5rem * 2)', 'calc(50% - 0.5rem * 2)', 'calc(50% - 0.5rem * 2)'],
      '.image': {
        width: ['60%', '80%', '60%'],
        border: 'solid 10px #cbb190',
        height: ['350px', '', '', '450px'],
        objectFit: 'cover',
        objectPosition: 'top',
        boxShadow: '20px 20px #b7cce6',
        marginBottom: '2rem',
        position: 'relative',
        left: '2rem'
      }
    },
    '.title': {
      variant: 'customVariants.title',
      color: 'primary',
      padding: '1rem 0rem'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      color: 'grey',
      padding: '1rem 0rem',
      marginBottom: '1rem',
      fontFamily: 'body',
      fontWeight: '300'
    },
    '.text': {
      variant: 'customVariants.text',

      opacity: '0.8',
      p: {
        fontSize: ['0.8rem', '0.9rem'],
        lineHeight: '1.7'
      }
    },
    '.section': {
      padding: '3rem 1rem'
    },
    '.content': {
      order: '2',
      padding: ['0rem 0.25rem 0rem 0.75rem', '1rem'],
      width: ['75%', '', '60%', '60%', '70%']
    },
    '.lazyload-wrapper': {
      width: ['25%', '', '40%', '40%', '30%'],
      img: {
        height: 'auto'
      }
    }
  },

  drSection: {
    variant: 'customVariants.sideBySide1',
    flexDirection: ['column', 'row'],
    maxWidth: '1000px',
    margin: '0 auto 3rem',
    padding: '1rem',
    '.lazyload-wrapper': {
      width: ['60%', '50%', '33.3%', '25%'],

      img: {
        maxHeight: '500px',
        objectFit: 'cover',
        objectPosition: 'top'
      }
    },
    '.content': {
      order: '2',
      width: ['100%', '50%', '66.6%', '75%'],
      padding: ['1rem', '2rem', '3rem 1rem 3rem 5rem', '4rem 1rem 4rem 6rem']
    },
    '.image': {
      border: 'solid 10px #cbb190',

      objectFit: 'cover',
      objectPosition: 'top',
      boxShadow: '20px 20px #b7cce6',
      marginBottom: '2rem',
      position: 'relative',
      left: ['2rem', '0rem'],
      maxHeight: '420px'
    },
    '.title': {
      variant: 'customVariants.title',
      color: 'primary',
      padding: '1rem 0rem'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      color: 'grey',
      padding: '1rem 0rem',
      marginBottom: '1rem',
      fontFamily: 'body',
      fontWeight: '300'
    },
    '.text': {
      variant: 'customVariants.text',

      opacity: '0.8',
      p: {
        fontSize: ['0.8rem', '0.9rem'],
        lineHeight: '1.7'
      }
    }
  },

  // ? ========================
  // ? =====  Menu page  =====
  // ? ========================

  menu: {
    // '.menuContainer': {},
    // '.tabsRow': {},
    // '#menuTab': {
    //   ':hover': {}
    // },
    // '.menuItemInnerContainer, .menuItemContentContainer': {},
    // '.menuSectionTitle': {},
    // '.menuSectionDescription': {
    //   mb: '1.5rem'
    // },

    // '.menuItemName': {},
    // '.menuItemDescription': {},
    // '.menuPriceContainer': {},
    // '.variantsContainer': {}

    maxWidth: 'unset',

    '.allInContainerWrapper': {
      maxWidth: '1000px',
      margin: '0 auto'
    },
    '.menuDropdownBtn': {
      marginBottom: '2rem',
      // fontFamily: 'Sparrowhawk',
      backgroundColor: '#333333',
      border: 'double 6px white'
    },
    '.menuSectionsDropDownContainer': {
      // fontFamily: 'Sparrowhawk',
      borderRadius: '0px',
      textAlign: 'center',
      boxShadow: 'none',
      display: 'none'
    },
    '.section-cell': {
      width: ['', '', '', '', 'calc(33.33% - (0.5rem * 2))']
    },
    '.menuCell': {
      border: 'solid 2px #333333',
      borderRadius: '0px'
    },

    '.cellName': {
      // fontFamily: 'Sparrowhawk',
      fontSize: '1.25rem'
    },
    '.cellImage': {
      borderRadius: '0rem',
      borderBottom: '2px solid black'
    },

    '.allInContainer': {
      margin: '1rem 0rem'
    },
    '.menuContainer': {
      paddingBottom: '1rem'
    },
    '.backToMenuBtn': {
      borderRadius: '500px',
      border: 'double 6px white'
    },
    '.menuSectionTitle': {
      textTransform: 'uppercase',
      letterSpacing: '1px',
      padding: '1rem 1rem 0.7rem',
      width: 'fit-content',
      margin: '0 auto 2rem',
      borderBottom: '7px double',
      borderColor: 'primary',
      fontFamily: 'Open Sans !important',
      fontWeight: '500',
      fontSize: '1.75rem'
    },
    '.menuSectionDescription': {
      fontFamily: 'Open Sans !important',
      margin: '1.25rem auto 2rem',
      maxWidth: '767px',
      fontSize: '1rem',
      textAlign: 'center',
      display: 'block',
      opacity: '0.5'
    },
    '.menuItemContainer': {
      flexGrow: '1',
      width: '100%',
      my: '1rem'
    },

    '.menuItemInnerContainer': {
      marginBottom: '0rem'
    },
    '.menuItemContentContainer': {
      padding: '0rem',
      alignItems: 'center'
    },
    '.itemVariantsContainer': {
      margin: '0rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: '0.5rem'
    },
    '.menuItemNamePriceContainer': {
      marginBottom: '1.5rem'
    },

    '.menuItemName': {
      fontFamily: 'Open Sans',
      marginBottom: '0rem',
      fontSize: ['1.25rem', '', '1.5rem'],
      textAlign: 'center',
      display: 'block',
      maxWidth: '767px'
    },

    '.menuItemPrice ': {
      fontFamily: 'Open Sans',
      fontSize: ['1.25rem', '', '1.5rem'],
      ml: '1rem'
    },
    '.menuItemDescription': {
      fontFamily: 'Open Sans !important',
      margin: '0.25rem 0rem',
      opacity: '0.5',
      textAlign: 'center',
      fontSize: ['0.9rem', '', '1rem']
    },

    '.dropdownContainer': {
      display: 'none!important'
    }
  },

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumsContainer': {},
    '.albumTitle': {}
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.no-events-container': {},
    '.eventItemImage': {},
    '.eventDays, .eventDate': {}
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    form: {
      input: {},

      textarea: {},
      '.submitBtn': {
        variant: 'buttons.secondary'
      }
    }
  },

  locationMap: {
    order: '2',
    h3: {
      variant: 'customVariants.title'
    },

    '.dayofWeekText': {}
  },

  // ? ========================
  // ? ====  Press Menu  ====
  // ? ========================

  pressMenu: {},

  pressPage: {},

  // ? ========================
  // ? ====  Team Menu  ====
  // ? ========================

  teamMenu: {},

  teamPage: {},

  // ? ========================
  // ? ====  blog Menu  ====
  // ? ========================

  blogMenu: {},

  blogPage: {}
}
